import { FC } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  Root,
  loader as rootLoader,
  action as rootAction,
} from "./routes/Root";

import {
  Contact,
  loader as contactLoader,
  action as contactAction,
} from "./routes/Contact";
import { action as editAction, EditContact } from "./routes/Edit";
import { action as destroyAction } from "./routes/Destroy";
import { Index } from "./routes/Index";

import { ErrorPage } from "./components/ErrorPage";
import "./style.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    action: rootAction,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <Index /> },
          {
            path: "contacts/:contactId",
            element: <Contact />,
            loader: contactLoader,
            action: contactAction,
          },
          {
            path: "contacts/:contactId/edit",
            element: <EditContact />,
            loader: contactLoader,
            action: editAction,
          },
          {
            path: "contacts/:contactId/destroy",
            action: destroyAction,
          },
        ],
      },
    ],
  },
]);
export const App: FC = () => {
  return <RouterProvider router={router} />;
};
