import type { FC } from "react";
import { Outlet, redirect, useNavigation } from "react-router-dom";
import { SearchForm } from "../components/SearchForm";
import { Contacts } from "../components/Contacts";
import type { LoaderFunction, ActionFunction } from "react-router-dom";
import { createContact, getContacts } from "../contacts";

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const q = url.searchParams.get("q") ?? "";
  const contacts = await getContacts(q);
  console.log("contacts:", contacts);
  return { contacts, q };
};

export const action: ActionFunction = async () => {
  const contact = await createContact();
  return redirect(`/contacts/${contact.id}/edit`);
};

export const Root: FC = () => {
  const { state } = useNavigation();
  return (
    <>
      <div id="sidebar">
        <h1>Contacts powered by ynidev</h1>
        <SearchForm />
        <Contacts />
      </div>
      <div id="detail" className={state === "loading" ? "loading" : ""}>
        <Outlet />
      </div>
    </>
  );
};
